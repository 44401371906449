//////////////////////////////////////////////////////////////////
// Main dashboard for website => currently holds dummy 
// charts and tables
//////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////
// Imports
//////////////////////////////////////////////////////////////////

import Template from '../template/Template';
import Copyright from '../template/Copyright';
import { useEffect } from 'react';

//////////////////////////////////////////////////////////////////
// Functions
//////////////////////////////////////////////////////////////////

const Content = () => {
  // Update DOM with initViz and re-render
  useEffect(() => { window.initViz() }, [])
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div>
        <div id="vizContainer" style={{ width: '80vw' }} />
        <Copyright />
      </div>
    </div>
  )
}

//////////////////////////////////////////////////////////////////
// Exports
//////////////////////////////////////////////////////////////////

export default function Dashboard() {
  return (
    <Template name={"Tableau Graphs"} Content={Content} />
  );
}