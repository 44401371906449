//////////////////////////////////////////////////////////////////
// Main JS file for ShopGrok Website
//////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////
// Imports
//////////////////////////////////////////////////////////////////

import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Login from './login/Login';
import Dashbaord from './dashboard/Dashboard';
import Signup from './signup/Signup';
import ProductTable from './product_match/Table';
import Tableau from './tableau/Tableau';
import NotFound from './notfound/NotFound';

//////////////////////////////////////////////////////////////////
// Functions
//////////////////////////////////////////////////////////////////

const App = () => {
  return (
    <div>
      <Router>
        <Switch>
          <Route exact path='/' children={<Redirect to='/login'/>}/>
          <Route path='/login' children={<Login />}/>
          <Route path='/signup' children={<Signup />}/>
          <Route path='/dashboard' children={<Dashbaord />}/>
          <Route path='/product-match' children={<ProductTable />}/>
          <Route path='/tableau' children={<Tableau />}/>
          <Route children={<NotFound />}/>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
