////////////////////////////////////////////////////////////////////////
// Not Found Page
////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
// Imports
////////////////////////////////////////////////////////////////////////

import './Notfound.css';

////////////////////////////////////////////////////////////////////////
// Return React element/page that displays "Not Found" error message
////////////////////////////////////////////////////////////////////////

export default function Notfound() {
  return (
    // TODO: Create a Not Found page
    // Preferable a page that is visually nice and follows similar 
    // style to main website but up to you there is no 'standard'
    // just visually friendly when someone sees this page :)
    <text>dummy text</text>
  )
}