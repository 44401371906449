////////////////////////////////////////////////////////////////////////
// Product Match Table
////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
// Imports
////////////////////////////////////////////////////////////////////////

import Template from '../template/Template';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Divider from '@material-ui/core/Divider';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import FilterListIcon from '@material-ui/icons/FilterList';
import Copyright from '../template/Copyright';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Overlay from 'react-bootstrap/OverlayTrigger';
import BootTooltip from 'react-bootstrap/Tooltip';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import LoadGIF from '../assets/loadDefault.gif';

////////////////////////////////////////////////////////////////////////
// Sample Data from post request to flask (w. snowflake)
////////////////////////////////////////////////////////////////////////

let auth = { auth: { 'username': 'Shopgrok-client', 'password': 'abcde' } }
var rows = [];
axios.post('https://backend-dot-product-match-app.ts.r.appspot.com/', {}, auth)
  .then((response) => {
    let l = response.data['snowflake_data'];
    for (let i = 0; i < l.length; i++) {
      rows.push(createData(i, l[i][0], l[i][1], l[i][2]))
    }
  })
  .catch((error) => {
    console.log(error);
  });

////////////////////////////////////////////////////////////////////////
// Define field names and their behaviour
// NOTE!!! 
// The original material ui code base was not scalable
// In order to add/delete fields, all following functions must be 
// modified accordingly. 
// DO NOT MODIFY "main" FIELD HOWEVER!!!!
////////////////////////////////////////////////////////////////////////

// Creates objects with given paramters
const createData = (main, mtpye, cm, status) => {
  return { main, mtpye, cm, status };
}

// Headers for table
const headCells = [
  { id: 'main', numeric: false, disablePadding: true, label: 'Row ID' },
  { id: 'mtpye', numeric: true, disablePadding: false, label: 'Match Type' },
  { id: 'cm', numeric: true, disablePadding: false, label: 'Cup Multiplier' },
  { id: 'status', numeric: true, disablePadding: false, label: 'Status' },
];

var initialOrderBy = 'main';

const returnEditable = (index) => {
  return [
    rows[index].main,
    rows[index].mtpye,
    rows[index].cm,
    rows[index].status
  ]
}

const updateTable = (index, curr) => {
  rows[index].main = curr[0];
  rows[index].mtpye = curr[1];
  rows[index].cm = curr[2];
  rows[index].status = curr[3];
}

const checkEditFormRestrictions = (curr) => {
  if (isNaN(curr[0])) {
    alert("Row ID must be numerical");
    return false;
  }
  return true;
}

const formFields = (index, curr) => {
  return [
    ["Row ID", rows[index].main, 0],
    ["Match Type", rows[index].mtpye, 1],
    ["Cup Multiplier", rows[index].cm, 2],
    ["Status", rows[index].status, 3],
  ]
}

const renderRow = (row, labelId) => {
  return (
    <>
      <TableCell component="th" id={labelId} scope="row" padding="none">
          {row.main}
      </TableCell>
      <Overlay overlay={<BootTooltip>hi</BootTooltip>} placement={'right'}>
        <TableCell align="right">{row.mtpye}</TableCell>
      </Overlay>
      <TableCell align="right">{row.cm}</TableCell>
      <TableCell align="right">{row.status}</TableCell>
    </>
  )
}

////////////////////////////////////////////////////////////////////////
// Helper functions
////////////////////////////////////////////////////////////////////////

// Comparator for ordering
const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

// Decide what comparator to use
const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Stable sort algorithm
const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

////////////////////////////////////////////////////////////////////////
// Subject Headers => contains Match Type, Cup Multiplier etc
////////////////////////////////////////////////////////////////////////

const EnhancedTableHead = (props) => {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => { onRequestSort(event, property) };
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

// Define prop types for EnhancedTableHead
EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

////////////////////////////////////////////////////////////////////////
// Toolbar Headers => turns red upon deletion prompt
////////////////////////////////////////////////////////////////////////

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    backgroundColor: '#566787',
    color: 'white'
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: { flex: '1 1 100%' },
  buttons: {
    display: 'flex',
    flexDirection: 'row'
  },
  modalCanvas: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: '100vh',
    minHeight: '400px'
  },
  formField: {
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: 'column',
    height: '52vh',
    minHeight: '500px',
    width: 'calc(20px + 35vw)',
    minWidth: '300px',
    borderRadius: 20,
  },
  inputFields: {
    width: '30vw',
    minWidth: '250px',
  },
  bigfont: {
    fontSize: '30px',
    marginBottom: '5px'
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
}));

const DeleteAlert = (yCondition, nCondition, setSelected) => {
  confirmAlert({
    title: 'Confirm Delete',
    message: 'Deleting a match(es) will permanently delete from the database and table.',
    buttons: [
      {
        label: 'Continue', onClick: () => {
          yCondition();
          setSelected([]);
        }
      },
      { label: 'Cancel', onClick: nCondition }
    ]
  });
}

const EditAlert = (yCondition, nCondition, setSelected) => {
  confirmAlert({
    title: 'Confirm Edit',
    message: 'Editing a match(es) will permanently edit the record(s) in the database and table.',
    buttons: [
      {
        label: 'Continue', onClick: () => {
          yCondition();
          setSelected([]);
        }
      },
      { label: 'Cancel', onClick: nCondition }
    ]
  });
}

const deleteRows = (selected) => {
  for (let i of selected) {
    for (let j = 0; j < rows.length; j++) {
      if (rows[j].main === i) rows.splice(j, 1);
    }
  }
}

// Tool bar => turn red when deletion prompt
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, selected, setSelected } = props;
  const [show, setShow] = useState(false);
  return (
    <Toolbar
      className={clsx(classes.root, { [classes.highlight]: numSelected > 0 })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div" />
      )}
      {numSelected > 0 ? (
        <div className={classes.buttons}>
          {numSelected === 1 ? (
            <Tooltip title="Edit">
              <IconButton aria-label="Edit">
                <EditIcon onClick={() => {
                  setShow(true);
                }} />
              </IconButton>
            </Tooltip>
          ) : (null)}
          <Tooltip title="Delete">
            <IconButton aria-label="delete">
              <DeleteIcon onClick={() => {
                DeleteAlert(() => deleteRows(selected), () => { return }, setSelected)
              }} />
            </IconButton>
          </Tooltip>
          <Modal
            open={show}
            onClose={() => setShow(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modalCanvas}
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <UserForm setShow={setShow}
              setSelected={setSelected}
              selected={selected}
            />
          </Modal>
        </div>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

// Proptypes for EnhancedTableToolbar
EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const UserForm = ({ setShow, setSelected, selected }) => {
  const classes = useToolbarStyles();
  let index;
  for (let i of selected) {
    for (let j = 0; j < rows.length; j++) {
      if (rows[j].main === i) {
        index = i;
        break;
      }
    }
  }
  let curr = returnEditable(index);
  let items = formFields(index, curr);
  return (
    <form className={classes.formField}>
      <Form>
        <Typography className={classes.bigfont} color="inherit" component="h1">
          Edit Item
        </Typography>
        <Divider style={{ marginBottom: '10px' }} />
        {
          items.map((info) => {
            return (
              <Form.Group controlId="exampleForm.ControlInput1" className={classes.inputFields}>
                <Form.Label>{info[0]}</Form.Label>
                <Form.Control type="text" placeholder={info[1]} onChange={(e) => {
                  curr[info[2]] = e.target.value;
                }} />
              </Form.Group>
            )
          })
        }
        <Button
          variant="contained"
          color="primary"
          size="medium"
          startIcon={<SaveIcon />}
          onClick={() => {
            setShow(false);
            if (!checkEditFormRestrictions(curr)) return;
            EditAlert(
              () => {
                updateTable(index, curr);
                setSelected([]);
              },
              () => { return },
              setSelected
            )
          }}
        >
          Save
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          startIcon={<DeleteIcon />}
          onClick={() => { setShow(false) }}
        >
          Cancel
        </Button>
      </Form>
    </form>
  )
}

////////////////////////////////////////////////////////////////////////
// Main Table Component => export final table with all headers and 
// data from POST request
////////////////////////////////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 1500,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  app: {
    display: 'flex',
    justifyContent: 'center',
    height: '80vh'
  },
  tableContainer: {
    width: '100vw'
  },
  gifCanvas: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: '50vh',
    flexDirection: 'column'
  }
}));

const EnhancedTable = () => {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(initialOrderBy);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    initialOrderBy = property;
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.main);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (_, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (_, newPage) => { setPage(newPage) };
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className={classes.app}>
      <div className={classes.tableContainer}>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <EnhancedTableToolbar numSelected={selected.length}
              selected={selected} setSelected={setSelected}
            />
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size='medium'
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.main);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.main)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.main}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </TableCell>
                          {renderRow(row, labelId)}
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
          <Copyright />
        </div>
      </div>
    </div>
  );
}

const Loading = () => {
  const classes = useStyles();
  return (
    <div className={classes.gifCanvas}>
      <img src={LoadGIF} style={{ height: '50px' }} alt="loading..." />
    </div>
  )
}

////////////////////////////////////////////////////////////////////////
// Function/Exports
////////////////////////////////////////////////////////////////////////

export default function ProductMatch() {
  const [state, setState] = useState(<Template name={'Product Match Table'} Content={Loading} />)
  useEffect(() => {
    let loaded = false;
    const timer = setInterval(() => {
      if (rows.length !== 0) {
        setState(<Template name={'Product Match Table'} Content={EnhancedTable} />)
        clearInterval(timer);
        loaded = true;
      }
    }, 250);
    setTimeout(() => {
      if (!loaded) {
        clearInterval(timer)
        alert("Data took too long to load. Please reload and try again.")
      }
    }, 5000)
  }, []);
  return state;
}
