//////////////////////////////////////////////////////////////////
// Main function => ShopGrok
//////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////
// Imports
//////////////////////////////////////////////////////////////////

import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

//////////////////////////////////////////////////////////////////
// Exports
//////////////////////////////////////////////////////////////////

export default function Copyright() {
    return (
    <Box pt={4} style={{paddingBottom: '45px'}}>
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://shop-grok.com/">
          ShopGrok
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </Box>
    );
  }