//////////////////////////////////////////////////////////////////
// Main dashboard for website => currently holds dummy 
// charts and tables
//////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////
// Imports
//////////////////////////////////////////////////////////////////

import Template from '../template/Template';
import Chart from './Chart';
import Deposits from './Deposits';
import Orders from './Orders';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Copyright from '../template/Copyright';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

const Content = () => {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  return (
    <div>
      <Grid container spacing={3}>
        {/* Chart */}
        <Grid item xs={12} md={8} lg={9}>
          <Paper className={fixedHeightPaper}>
            <Chart />
          </Paper>
        </Grid>
        {/* Recent Deposits */}
        <Grid item xs={12} md={4} lg={3}>
          <Paper className={fixedHeightPaper}>
            <Deposits />
          </Paper>
        </Grid>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Orders />
          </Paper>
        </Grid>
      </Grid>
      <Copyright />
    </div>
  )
}

//////////////////////////////////////////////////////////////////
// Exports
//////////////////////////////////////////////////////////////////

export default function Dashboard() {
  return (
    <Template name={"Dashboard"} Content={Content}/>
  );
}